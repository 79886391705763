import React from 'react'
import moment from 'moment';
import { Link } from 'react-router-dom';


const Item = (props) => {

    const { locale, data } = props;
    const { id, alias, image, title, user, date, tag, category, content } = data;


    return (

        <div className="grid grid-cols-1">
            <div>
                <Link to={`/${locale}/article/${alias}`} className="group">
                    <img src={image} alt="" className="object-fill w-full rounded-md inline-block group-hover:opacity-75" loading="lazy" />
                </Link>
            </div>

            <div className="w-full block text-black py-8">
                <Link to={`/${locale}/article/${alias}`}>
                    <span className="bg-orange-tgl px-5 py-1 rounded-md text-white">{category}</span>
                    <h1 className="text-xl py-3">{title}</h1>
                    <h3 className="text-gray-400 pb-3"><span className="text-orange-tgl">{user}</span>, {moment(date).format("ll")}</h3>
                    <p className="text-gray-400">{content}</p>
                </Link>
            </div>
        </div>
    );
};

export default Item;
