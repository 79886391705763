import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const Header = ({ t, locale }) => {

    const location = useLocation();
    const regex = /\/(en|pt)\//gm;

    var curUrl = location.pathname;

    return (
        <div>
            Header
            <Link to={curUrl.replace(regex, "/pt/")} onClick={() => locale.changeLanguage("pt")}>
                PT
            </Link>
            <Link to={curUrl.replace(regex, "/en/")} onClick={() => locale.changeLanguage("en")} >
                EN
            </Link>
        </div>
    );
};

export default Header;
