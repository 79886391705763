import React from 'react'
import { useTranslation } from 'react-i18next';
import Item from './Item';

const NewsBlock = (props) => {

    const { t, i18n } = useTranslation();

    const locale = i18n.language;

    return (
        <div>
            {/* Samll news 3 COLS */}
            <div className="max-w-4xl mx-auto px-4 my-10 grid lg:grid-cols-3 gap-8 md:grid-cols-2 sm:grid-cols-1">
                <Item locale={locale} data={{ id: 1, alias: "news-1", image: "https://thegeekloft.pt/u-files/2018-02-03-18-45-31-Total-263268.jpg", title: "Fe – Trailer de lançamento", user: "Shootie", date: new Date(), tag: "video", category: "Geral", content: "Recentemente foi anunciado um jogo de Mario Kart para os dispositivos moveis, mas pouco ou nada se sabia sobre o mesmo. O diretor executivo da DeNa, Isao Moriyasu, decidiu partilhar uma nova informação sobre o jogo. Mario Kart Tour vai ser um jogo gratuito, no entanto para poder continuar a jogar e progredir no jogo vai ter de se pagar." }} />
                <Item locale={locale} data={{ id: 1, alias: "news-1", id: 1, alias: "news-1", "image": "https://thegeekloft.pt/u-files/2018-02-03-18-45-31-Total-263268.jpg", title: "Kraken em Sea of Thieves", user: "Shootie", date: new Date(), tag: "gallery", category: "Geral", content: "Recentemente foi anunciado um jogo de Mario Kart para os dispositivos moveis, mas pouco ou nada se sabia sobre o mesmo. O diretor executivo da DeNa, Isao Moriyasu, decidiu partilhar uma nova informação sobre o jogo. Mario Kart Tour vai ser um jogo gratuito, no entanto para poder continuar a jogar e progredir no jogo vai ter de se pagar." }} />
                <Item locale={locale} data={{ id: 1, alias: "news-1", "image": "https://thegeekloft.pt/u-files/2018-02-03-18-45-31-Total-263268.jpg", title: "Far Cry 3 – GIVEAWAY", user: "Shootie", date: new Date(), tag: "", category: "Geral", content: "Recentemente foi anunciado um jogo de Mario Kart para os dispositivos moveis, mas pouco ou nada se sabia sobre o mesmo. O diretor executivo da DeNa, Isao Moriyasu, decidiu partilhar uma nova informação sobre o jogo. Mario Kart Tour vai ser um jogo gratuito, no entanto para poder continuar a jogar e progredir no jogo vai ter de se pagar." }} />
                <Item locale={locale} data={{ id: 1, alias: "news-1", "image": "https://thegeekloft.pt/u-files/2018-02-03-18-45-31-Total-263268.jpg", title: "Far Cry 3 – GIVEAWAY", user: "Shootie", date: new Date(), tag: "", category: "Geral", content: "Recentemente foi anunciado um jogo de Mario Kart para os dispositivos moveis, mas pouco ou nada se sabia sobre o mesmo. O diretor executivo da DeNa, Isao Moriyasu, decidiu partilhar uma nova informação sobre o jogo. Mario Kart Tour vai ser um jogo gratuito, no entanto para poder continuar a jogar e progredir no jogo vai ter de se pagar." }} />
                <Item locale={locale} data={{ id: 1, alias: "news-1", "image": "https://thegeekloft.pt/u-files/2018-02-03-18-45-31-Total-263268.jpg", title: "Far Cry 3 – GIVEAWAY", user: "Shootie", date: new Date(), tag: "", category: "Geral", content: "Recentemente foi anunciado um jogo de Mario Kart para os dispositivos moveis, mas pouco ou nada se sabia sobre o mesmo. O diretor executivo da DeNa, Isao Moriyasu, decidiu partilhar uma nova informação sobre o jogo. Mario Kart Tour vai ser um jogo gratuito, no entanto para poder continuar a jogar e progredir no jogo vai ter de se pagar." }} />
                <Item locale={locale} data={{ id: 1, alias: "news-1", "image": "https://thegeekloft.pt/u-files/2018-02-03-18-45-31-Total-263268.jpg", title: "Far Cry 3 – GIVEAWAY", user: "Shootie", date: new Date(), tag: "", category: "Geral", content: "Recentemente foi anunciado um jogo de Mario Kart para os dispositivos moveis, mas pouco ou nada se sabia sobre o mesmo. O diretor executivo da DeNa, Isao Moriyasu, decidiu partilhar uma nova informação sobre o jogo. Mario Kart Tour vai ser um jogo gratuito, no entanto para poder continuar a jogar e progredir no jogo vai ter de se pagar." }} />
                <Item locale={locale} data={{ id: 1, alias: "news-1", "image": "https://thegeekloft.pt/u-files/2018-02-03-18-45-31-Total-263268.jpg", title: "Far Cry 3 – GIVEAWAY", user: "Shootie", date: new Date(), tag: "", category: "Geral", content: "Recentemente foi anunciado um jogo de Mario Kart para os dispositivos moveis, mas pouco ou nada se sabia sobre o mesmo. O diretor executivo da DeNa, Isao Moriyasu, decidiu partilhar uma nova informação sobre o jogo. Mario Kart Tour vai ser um jogo gratuito, no entanto para poder continuar a jogar e progredir no jogo vai ter de se pagar." }} />
                <Item locale={locale} data={{ id: 1, alias: "news-1", "image": "https://thegeekloft.pt/u-files/2018-02-03-18-45-31-Total-263268.jpg", title: "Far Cry 3 – GIVEAWAY", user: "Shootie", date: new Date(), tag: "", category: "Geral", content: "Recentemente foi anunciado um jogo de Mario Kart para os dispositivos moveis, mas pouco ou nada se sabia sobre o mesmo. O diretor executivo da DeNa, Isao Moriyasu, decidiu partilhar uma nova informação sobre o jogo. Mario Kart Tour vai ser um jogo gratuito, no entanto para poder continuar a jogar e progredir no jogo vai ter de se pagar." }} />
                <Item locale={locale} data={{ id: 1, alias: "news-1", "image": "https://thegeekloft.pt/u-files/2018-02-03-18-45-31-Total-263268.jpg", title: "Far Cry 3 – GIVEAWAY", user: "Shootie", date: new Date(), tag: "", category: "Geral", content: "Recentemente foi anunciado um jogo de Mario Kart para os dispositivos moveis, mas pouco ou nada se sabia sobre o mesmo. O diretor executivo da DeNa, Isao Moriyasu, decidiu partilhar uma nova informação sobre o jogo. Mario Kart Tour vai ser um jogo gratuito, no entanto para poder continuar a jogar e progredir no jogo vai ter de se pagar." }} />
            </div>
        </div>
    );
};

export default NewsBlock;
