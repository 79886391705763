import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        browserLanguageDetection: true,
        fallbackLng: 'pt',
        otherLanguages: ['en'],
        load: 'languageOnly',
        ignoreRoutes: ['/public/', '/robots.txt', '/sitemap.xml'],
        localeSubpaths: {
            pt: 'pt',
            en: 'en'
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        detection: {
            order: ["path", "cookie", "navigator"],
            lookupFromPathIndex: 0,
            lookupCookie: "next-i18next",
            excludeCacheFor: ['cimode'],
        },
        react: {
            bindI18n: 'languageChanged',
            useSuspense: false
        }
    });

export default i18n;