import styles from './styles/home.module.css';

import { useTranslation } from 'react-i18next';

const MtgHome = () => {

    const { t } = useTranslation();


    return (
        <div>
            MTG Home
        </div>);
};

export default MtgHome;