// Layouts Imports
import DefaultLayout from "../../components/layouts/DefaultLayout";
import MtgLayout from "../../components/layouts/MtgLayout";


// Component Imports
import Home from "../../pages/tgl/home";
import Article from "../../pages/tgl/article";
import MtgHome from "../../pages/mtg/home";

import {
    Navigate
} from "react-router-dom";

let routes = [
    {
        path: '/',
        element: <Navigate to="/pt/" />
    },
    {
        path: '/:lang/',
        element: <DefaultLayout />,
        children: [
            { index: true, element: <Home /> }
        ]
    },
    {
        path: '/:lang/article/',
        element: <DefaultLayout />,
        children: [
            { index: true, element: <Article /> },
            { path: ':alias', element: <Article /> }
        ]
    },
    {
        path: '/:lang/mtg',
        element: <MtgLayout />,
        children: [
            { index: true, element: <MtgHome /> }
        ]
    }
];

export default routes;