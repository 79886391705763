import React from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Header from './Header';

const MtgLayout = () => {

    const { t, i18n } = useTranslation();

    return (
        <div>
            <p>Mtg Layout</p>
            <Header t={t} locale={i18n} />
            <Outlet />
        </div>
    );
};

export default MtgLayout;
