import React from 'react';
import Helmet from 'react-helmet';
import { useRoutes } from 'react-router-dom';


import routes from '../middlewares/routers';

function App() {

  const router = useRoutes(routes);

  return (
    <div className="app">
      <Helmet>
        <title>TheGeekLoft</title>
        <meta name="description" content="TheGeekLoft" />
        <meta name="author" content="One:Shift at one-shift.com" />
        <meta name="author-code" content="Marcos Silva" />
      </Helmet>
      {router}
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4929279973769263"
        crossOrigin="anonymous"></script>
    </div >

  )
}

export default App
