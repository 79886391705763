import React from 'react'
import moment from 'moment';
import { Link } from 'react-router-dom';

const Item = (props) => {

    const { locale, data } = props;
    const { id, alias, image, title, user, date, tag, category } = data;


    return (

        <Link to={`/${locale}/article/${alias}`}>
            <div className="grid grid-cols-1">
                <div className="">
                    <img src={image} alt="" className="object-fill w-full object-bottom rounded-md inline-block" loading="lazy" />
                </div>

                <div className="w-full relative block text-white -mt-28 px-4 bg-gradient-to-b from-transparent to-black rounded-md">
                    <span className="bg-orange-tgl px-5 py-1 rounded-md">{category}</span>
                    <h1 className="text-xl py-3">{title}</h1>
                    <p className="">{user}, {moment(date).format("ll")}</p>
                </div>
            </div>
        </Link>
    );
};

export default Item;
