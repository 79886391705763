import React from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Header from './Header';

const DefaultLayout = (props) => {

    const { t, i18n } = useTranslation(["menu"]);

    return (
        <div>
            <Header t={t} locale={i18n} />
            <Outlet />
        </div>
    );
};

export default DefaultLayout;
