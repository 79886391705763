import React from 'react'
import { useTranslation } from 'react-i18next';
import Item from './Item';

const FeatureNews = (props) => {

    const { t, i18n } = useTranslation();
    const locale = i18n.language;

    return (
        <div>
            {/* BIG NEWS 3 COLS */}
            <div className="max-w-6xl mx-auto px-4 my-10 grid lg:grid-cols-3 gap-8 md:grid-cols-2 sm:grid-cols-1">
                <Item locale={locale} data={{ id: 1, alias: "news-1", image: "https://thegeekloft.pt/u-files/2018-02-16-22-34-10-Fe-360466.jpg", title: "Fe – Trailer de lançamento", user: "Shootie", date: new Date(), tag: "video", category: "Geral" }} />
                <Item locale={locale} data={{ id: 1, alias: "news-1", "image": "https://thegeekloft.pt/u-files/2018-02-15-20-10-42-sot1-360466.jpg", title: "Kraken em Sea of Thieves", user: "Shootie", date: new Date(), tag: "gallery", category: "Geral" }} />
                <Item locale={locale} data={{ id: 1, alias: "news-1", "image": "https://thegeekloft.pt/u-files/2018-02-14-18-56-07-1header-360466.jpg", title: "Far Cry 3 – GIVEAWAY", user: "Shootie", date: new Date(), tag: "", category: "Geral" }} />
            </div>
        </div>
    );
};

export default FeatureNews;
