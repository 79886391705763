import { useState } from "react";

import styles from './styles/home.module.css';

import { useTranslation } from 'react-i18next';
import FeatureNews from "../../../components/FeatureNews";
import NewsBlock from "../../../components/NewsBlock";

const Home = () => {

    const [count, setCount] = useState(0);

    const { t } = useTranslation();


    return (
        <div id="home">
            <FeatureNews />
            <NewsBlock />
        </div>
    );
};

export default Home;