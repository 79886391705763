import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import AdSense from 'react-adsense';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSteamSquare, faTwitterSquare, faFacebookSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'

const Header = ({ t, locale }) => {

    const location = useLocation();
    const regex = /\/(en|pt)\//gm;

    var curUrl = location.pathname;

    const [mobileHidden, setMobileHidden] = useState(true);

    return (
        <header>

            <div className="max-w-6xl mx-auto px-4 my-10 grid grid-cols-4 gap-4 h-32">
                <div className="flex items-center justify-center">
                    <a href="#">
                        <img src="https://thegeekloft.pt/shared/images/logo-header.png" alt="Logo" className="w-50 mr-2" />
                    </a>
                </div>
                <div className="hidden md:block col-span-2">
                    <AdSense.Google
                        client='ca-pub-4929279973769263'
                        slot='7143036893'
                    />
                </div>
                <div className="grid grid-cols-1 gap-4 col-span-4 md:col-span-1">
                    <div className="flex items-center justify-center">
                        <input className="appearance-none rounded w-full py-2 px-3 bg-gray-100 border-0 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="search" type="text" placeholder="search" />
                    </div>

                    <div className="flex items-center justify-center">
                        <button className={`flex flex-row items-center w-25 px-4 py-4 text-white font-montserrat font-normal uppercase ${locale.language === "pt" ? "bg-[#d95b1f]" : "bg-orange-tgl"}  hover:bg-[#d95b1f] transition duration-100`}>
                            <Link to={curUrl.replace(regex, "/pt/")} onClick={() => locale.changeLanguage("pt")}>
                                PT
                            </Link>
                        </button>
                        <button className={`flex flex-row items-center w-25 px-4 py-4 text-white font-montserrat font-normal uppercase ${locale.language === "en" ? "bg-[#d95b1f]" : "bg-orange-tgl"}  hover:bg-[#d95b1f] transition duration-100`}>
                            <Link to={curUrl.replace(regex, "/en/")} onClick={() => locale.changeLanguage("en")} >
                                EN
                            </Link>
                        </button>
                    </div>

                </div>
            </div>

            <nav className="bg-orange-tgl shadow-lg mb-10">
                <div className="max-w-6xl mx-auto px-4">
                    <div className="flex lg:justify-between sm:justify-center">
                        <div className="flex space-x-7">
                            <div>
                                {/* <!-- Website Logo --> */}
                                <a href="#" className="flex items-center px-2 hidden">
                                    <img src="https://thegeekloft.pt/shared/images/logo-header.png" alt="Logo" className="h-14 mr-2" />
                                </a>
                            </div>
                            {/* <!-- Primary Navbar items --> */}
                            <div className="hidden md:flex items-center space-x-1">
                                <nav aria-label="primary" className="relative z-20 flex-col flex-grow hidden pb-4 md:pb-0 md:flex md:justify-end md:flex-row">
                                    <div className="relative">
                                        <Link to={`/${locale.language}/`} className="flex flex-row items-center w-full px-4 py-4 text-white hover:bg-[#d95b1f] transition duration-100">
                                            <span className="font-montserrat font-normal uppercase">{t("menu:home")}</span>
                                        </Link>
                                    </div>
                                    <div className="relative">
                                        <Link to={`/${locale.language}/news/`} className="flex flex-row items-center w-full px-4 py-4 text-white hover:bg-[#d95b1f] transition duration-100">
                                            <span className="font-montserrat font-normal uppercase">{t("menu:news")}</span>
                                        </Link>
                                    </div>
                                    <div className="relative">
                                        <Link to={`/${locale.language}/reviews/`} className="flex flex-row items-center w-full px-4 py-4 text-white hover:bg-[#d95b1f] transition duration-100">
                                            <span className="font-montserrat font-normal uppercase">{t("menu:reviews")}</span>
                                        </Link>
                                    </div>
                                    <div className="relative">
                                        <Link to={`/${locale.language}/videos/`} className="flex flex-row items-center w-full px-4 py-4 text-white hover:bg-[#d95b1f] transition duration-100">
                                            <span className="font-montserrat font-normal uppercase">{t("menu:videos")}</span>
                                        </Link>
                                    </div>
                                    <div className="relative">
                                        <Link to={`/${locale.language}/guides/`} className="flex flex-row items-center w-full px-4 py-4 text-white hover:bg-[#d95b1f] transition duration-100">
                                            <span className="font-montserrat font-normal uppercase">{t("menu:guides")}</span>
                                        </Link>
                                    </div>
                                    <div className="relative group">
                                        <button className="flex flex-row items-center w-full px-4 py-4 text-white hover:bg-[#d95b1f] transition duration-100">
                                            <span className="font-montserrat font-normal uppercase">{t("menu:about_us")}</span>
                                        </button>
                                        <div className="absolute z-10 hidden bg-grey-200 w-100 group-hover:block">

                                            <div className="px-2 pt-2 pb-4 bg-white bg-gray-200 shadow-lg">
                                                <div className="grid grid-cols-1 gap-4">
                                                    <Link to={`/${locale.language}/about/thegeekloft/`} className="flex flex-row items-center w-full px-4 py-4 text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">
                                                        {t("menu:tgl")}
                                                    </Link>
                                                    <Link to={`/${locale.language}/about/staff/`} className="flex flex-row items-center w-full px-4 py-4 text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">
                                                        {t("menu:staff")}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <button className="flex flex-row items-center w-full px-4 py-4 text-white hover:bg-[#d95b1f] transition duration-100">
                                            <span className="font-montserrat font-normal uppercase">{t("menu:contacts")}</span>
                                        </button>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        {/* <!-- Secondary Navbar items --> */}
                        <div className="hidden md:hidden items-center space-x-3 lg:flex">
                            <a href="" className="flex flex-row items-center w-full h-full px-4 py-4 text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">
                                <FontAwesomeIcon icon={faFacebookSquare} />
                            </a>
                            <a href="" className="flex flex-row items-center w-full h-full px-4 py-4 text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">
                                <FontAwesomeIcon icon={faTwitterSquare} />
                            </a>
                            <a href="" className="flex flex-row items-center w-full px-4 h-full py-4 text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">
                                <FontAwesomeIcon icon={faYoutubeSquare} />
                            </a>
                            <a href="" className="flex flex-row items-center w-full px-4 py-4 h-full text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">
                                <FontAwesomeIcon icon={faSteamSquare} />
                            </a>
                        </div>
                        {/* <!-- Mobile menu button --> */}
                        <div className="md:hidden flex items-center">
                            <button className="outline-none mobile-menu-button p-4 text-center text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100" onClick={() => {
                                setMobileHidden(!mobileHidden);
                            }}>
                                <svg className=" w-6 h-6 text"
                                    x-show="!showMenu"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                {/* <!-- mobile menu --> */}
                <div className={`mobile-menu ${mobileHidden ? "hidden" : ""}`}>
                    <ul className="">
                        <li className="active"><a href="#" className="block text-center text-sm w-full px-4 py-4 h-full text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">home</a></li>
                        <li><a href="#services" className="block text-center text-sm w-full px-4 py-4 h-full text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">news</a></li>
                        <li><a href="#services" className="block text-center text-sm w-full px-4 py-4 h-full text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">reviwes</a></li>
                        <li><a href="#services" className="block text-center text-sm w-full px-4 py-4 h-full text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">videos</a></li>
                        <li><a href="#services" className="block text-center text-sm w-full px-4 py-4 h-full text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">guides</a></li>
                        <li><a href="#services" className="block text-center text-sm w-full px-4 py-4 h-full text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">about_us</a></li>
                        <li><a href="#services" className="block text-center text-sm w-full px-4 py-4 h-full text-white font-montserrat font-normal uppercase hover:bg-[#d95b1f] transition duration-100">contacts</a></li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;
