import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';

const Article = () => {

    let params = useParams();

    return (
        <div>
            <div className="App">
                <Helmet>
                    <title>Thegeekloft Geral / Kraken em Sea of Thieves</title>
                    <meta property="og:description" content="Sea of Thieves não para de surpreender. O jogo ainda não foi lançado, mas quem experimentou a beta quer mais.
Recentemente for" />
                    <meta property="og:title" content="Thegeekloft Geral / Kraken em Sea of Thieves" />
                    <meta property="og:image" content="https://thegeekloft.pt/u-files/2018-02-15-20-10-43-sot1-1020577.jpg" />
                    <meta property="og:url" content="https://thegeekloft.pt/pt/article/news-1" />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Thegeekloft" />
                </Helmet>
                {JSON.stringify(params)}
            </div>
        </div>
    );
};

export default Article;